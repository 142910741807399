<template>
    <div>
        <div id="nav" :class="{ mobile: onMobile }">
            <main-menu v-if="$route.name == 'Home'" />
            <loner-menu v-else />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import MainMenu from "@/components/MainMenu";
import LonerMenu from "@/components/LonerMenu";
export default {
  name: "Navigation",
  data() {
    return {};
  },
  metaInfo() {
    return {
      title: this.general.mainTitle,
    };
  },
  components: { MainMenu, LonerMenu },
  computed: {
    ...mapGetters({
      onMobile: "size/getMobile",
      general: "general/general",
    }),
  },
  watch: {
    /* eslint-enable no-unused-vars */
  },
};
</script>
<style lang="scss" >
#nav {
  width: 500px;
  box-sizing: border-box;
  max-height: 100%;
  overflow-y: auto;
  padding-top: 26px;

  #menuButton,
  #pageTitle {
    display: none;
  }

  #menuButtonCont {
    position: relative;
    width: 300px;
    height: $menuSize;
    cursor: pointer;
    background-color: white;
    position: fixed;
    z-index: 10;
  }
}

#menuButton {
  position: absolute;
  top: $globalPadding;
  right: $globalPadding;
  // transform: translateY(-50%);
}

#pageTitle,
#siteTitle {
  position: absolute;
  top: $globalPadding;
  left: $globalPadding;
  text-align: left;
}

#menuContainer {
  box-sizing: border-box;
}

@media screen and (max-width: $mobileBreak) {
  #nav {
    background-color: white;
    width: 100%;

    #menuButton,
    #pageTitle {
      display: block;
    }

    #siteTitle {
      display: none;
    }

    &.show {
      #menuButtonCont {
        height: 1 * $lineHeight + 2 * $globalPadding;
      }

      #menuContainer {
        display: block;
      }

      #pageTitle {
        display: none;
      }

      #siteTitle {
        display: block;
      }
    }


    #menuButtonCont {
      display: block;
      box-sizing: border-box;
      padding-left: calc(100vw - 100%);
      width: 100%;
    }
  }

  #menuContainer {
    // text-align: center;
    background-color: white;
    width: 100%;

    .linkList {
      width: 100%;
    }
  }

  #flyerContainer {
    display: none;
  }
}

.linkList {
  width: 100%;
  margin-bottom: 1.5em;

  .linkSectionTitle {
    margin-bottom: 1em;
  }
}

.nameContainer {
  display: flex;
}

a {
  color: black;
  text-decoration: none;
  transition: color 500ms;

  &.router-link-exact-active {
    color: black;
    cursor: default;
  }

  &:hover {
    color: black;
  }
}

a.link {
  width: 100%;
  position: relative;
  display: inline-block;
  // padding-left: 1em;
  box-sizing: border-box;
  // width: auto;
  &.lonerLink {
    margin-top: 2em;
  }
}


.router-link-exact-active,
#pageTitle {
}

a:hover .location {
  opacity: 1;
}

@media screen and (max-width: $mobileBreak) {
  a .location {
    opacity: 1;
  }
}
</style>