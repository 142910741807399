import Vue from 'vue'
import App from './App.vue'

import Store from "./store"
import router from './router'

import VScrollLock from 'v-scroll-lock'

import mixin from "./mixins"
import VueMeta from 'vue-meta'

Vue.config.productionTip = false
Vue.use(VScrollLock)

Vue.mixin(mixin)
Vue.use(VueMeta)
new Vue({
  render: h => h(App),
  router,
  mixins : [mixin],
  store : Store
}).$mount('#app')
