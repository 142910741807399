import Vue from 'vue'
import Vuex from 'vuex'

import Projects from "./projects.js"
import General from "./general"
import Size from "./size"

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    count: 0
  },
  mutations: {
    increment(state) {
      state.count++
    }
  },
  modules: {
    projects: Projects,
    general: General,
    size: Size
  }
})

export default store;