<template>
  <div id="app">
    <Header />
    <div id="pageContainer">
      <router-view :key="$route.path" />
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header";
export default {
    name: "Main",
    components: { Header },
    data() {
        return {
        };
    },
    mounted() {
    },

    computed: {
        currProject() {
            return this.$store.state.projects.currProject;
        },
    },
    methods: {},
};
</script>
<style lang="scss">
html,
body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
}
#app {
    // font-family: Avenir, Helvetica, Arial, sans-serif;
    font-family: $font;
    font-weight: $fontWeight;
    line-height: $lineHeight;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0px;
    font-size: $fontSize;
}
#pageContainer {
    width: 100%;
    padding-left: 300px;
    box-sizing: border-box;
    padding: $globalPadding;
    // padding-top: $menuSize;
}
@media screen and (max-width: 900px) {
    #pageContainer {
        // padding-left: 0px;
        #innerContainer {
            padding-top: 0px;
        }
    }
}
</style>
