<template>
  <div :key="project.id" id="projectContainer">
    <div id="projectTitle">
      <ExpoTitle :project="project" />
    </div>
    <div class="imageContainer contentElement flyerImage">
      <div class="imageFlexCont">
        <ImageElement :img="project.flyer" class="projectImage" />
      </div>
    </div>
    <template v-for="(item, j ) in project.contenu">
      <template v-if="item.type == 'photos'">
        <div v-for="(photo) in item.photos" :key="photo.id + ':' + j + ':' + item.id"
          class="imageContainer contentElement">
          <div class="imageFlexCont">
            <ImageElement :img="photo.image" class="projectImage" :alt="photo.desc" />
          </div>
          <div v-if="photo.desc != ''" :class="{ photoDescription: true }">{{ photo.desc }}</div>
        </div>
      </template>
      <div v-else-if="item.type == 'text'" :key="item.id" v-html="item.body" class="body_text contentElement" />
    </template>
  </div>
</template>


<script>
import ImageElement from "@/components/Image";
import ExpoTitle from "@/components/ExpoTitle";
import { mapGetters, mapActions } from "vuex";
// import lazyload from "lazyload";
export default {
  name: "Project",
  components: { ImageElement, ExpoTitle },
  metaInfo() {
    return {
      title: this.general.mainTitle + " - " + this.project.title,
    };
  },
  data() {
    return {
      currCaption: -1,
    };
  },
  created() {
    // this.loadProject(this.$route.params.project);
  },
  computed: {
    ...mapGetters({
      project: "projects/project",
      general: "general/general",
    }),
  },
  methods: {
    ...mapActions({
      loadProject: "projects/loadProject",
    }),
  },
};
</script>
<style lang="scss">
#projectContainer {}

div#projectTitle {
  padding-bottom: 10px;
  text-align: right;

  .location {
    opacity: 1;
  }
}

.imageContainer {
  .imageFlexCont {
    max-width: unset;
    display: flex;
    justify-content: flex-end;
  }

  .photoDescription {
    margin-top: 10px;
    text-align: right;
  }
}

.contentElement {
  margin-bottom: $globalPadding;
}

.body_text {
  max-width: 100%;
  width: 500px;
}

.contentElement:last-of-type {
  margin-bottom: 0px;
}

img.projectImage {
  width: auto;
  max-width: 80%;
  max-height: 90vh;
  height: auto;
  display: block;
}

@media screen and (max-width: 1000px) {
  img.projectImage {
    max-width: 100%;
  }
}


p {
  margin: $globalPadding 0px;
}

.flyerImage {
  display: block;
}

@media screen and (hover: hover) {
  .flyerImage {
    display: none;
  }
}

@media screen and (max-width: $mobileBreak) {
  .flyerImage {
    display: block;
  }

  .contentElement {
    margin-bottom: $globalPadding/2;
  }

  .imageContainer {
    .imageFlexCont {
      max-width: unset;
      justify-content: flex-end;
    }
  }
}

.contentElement:first-of-type :first-of-type {
  margin-top: 0px;
}

.contentElement:last-of-type :last-of-type {
  margin-bottom: 0px;
}</style>