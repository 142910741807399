<template>
  <div id="cont">
    <template v-if="(this.$route.name == 'Project' || this.$route.name == 'ProjectInContainer' || this.$route.name == 'LonerProject' ) && project.loaded">
      <ExpoTitle :project="project" />
    </template>
    <template v-if="this.$route.name == 'About'">
      <div>
        About
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ExpoTitle from "@/components/ExpoTitle";
export default {
    components: { ExpoTitle },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            general: "general/general",
            project: "projects/project",
        }),
    },
};
</script>

<style lang="scss">
div#cont {
    .location {
        opacity: 1;
    }
}

#cont {
    // position: absolute;
    // top: 50%;
    // left: 50px;
    // transform: translate(-50%, -50%);
}
</style>
