<template>
    <div>
        <div id="menuContainer">
            <template v-for="link in lonerProjects">
                <router-link :to="{ name: 'LonerProject', params: { project: link.slug } }" :key="link.id" class="link" @mouseenter.native="setCurrProject(link)" @mouseleave.native="setCurrProject(false)">
                    <ExpoTitle :project="link" />
                </router-link>
            </template>
        </div>
        <div id="flyerContainer">
            <template v-if="currProject">
                <ImageElement v-if="currProject.flyer" :img="currProject.flyer" alt />
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ExpoTitle from "@/components/ExpoTitle";
import ImageElement from "@/components/Image";
export default {
  metaInfo() {
    return {
      title: this.general.mainTitle + " - " + this.general.loner.title,
    };
  },
  mounted() {
    console.log(this.lonerProjects)
  },
  data() {
    return {
      currProject : false
    }
  },
  components: { ExpoTitle, ImageElement },
  computed: {
    ...mapGetters({
      general: "general/general",
      lonerProjects: "projects/lonerProjects",
    }),
  },
  methods: {
    setCurrProject(p) {
      this.currProject = p;
      console.log(p)
    },
  },
};
</script>

<style>
</style>