export default {
    namespaced: true,
    state: {
        mob: false,
        navOpen : false
    },
    mutations: {
        SET_MOBILE(state, val) {
            state.mob = val
        },
        SET_NAV(state, val) {
            state.navOpen = val;
        }
    },
    getters: {
        getMobile(state) {
            return state.mob
        }
    }
}