import Vue from 'vue'
import VueRouter from 'vue-router'
import Project from "@/views/Project"
import Home from "@/views/Home"

Vue.use(VueRouter)

import store from "@/store"
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/projects/',
    name: 'Projects',
    redirect: { name: 'Home' }
  },
  {
    path: '/loner/',
    name: 'LonerContainer',
    component: Home,
  },
  {
    path: '/loner/:project',
    name: 'LonerProject',
    params: true,
    component: Project,
  },
  {
    path: '/projects/:project',
    name: 'Project',
    params: true,
    component: Project
  },
  {
    path: '/projects/:projectContainer/:project',
    name: 'ProjectInContainer',
    params: true,
    component: Project
  },
  {
    path: '/about/',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  // {
  //   path: "/:catchAll(.*)",
  //   name: "NotFound",
  //   component: () => import(/* webpackChunkName: "notfound" */ '../views/PageNotFound.vue'),
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: (process.env.NODE_ENV !== 'production' ? "/gillesjacot" : ""),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  console.log(store.state.general.loaded)
  if (!store.state.general.loaded) {
    await store.dispatch("general/loadGeneral").catch(e => { console.log(e) })
  }
  if (!store.state.projects.loaded) {
    await store.dispatch("projects/loadProjects").catch(e => { console.log(e) })
  }
  if (to.name == "Project") {
    store.dispatch("projects/loadProject", "projects." + to.params.project).then(() => {
      next()
    })
      .catch(e => { console.log(e) })
  }
  else if (to.name == "ProjectInContainer") {
    store.dispatch("projects/loadProject", "projects." + to.params.projectContainer + "." + to.params.project).then(() => {
      next()
    })
      .catch(e => { console.log(e) })
  }
  else if (to.name == "LonerProject") {
    store.dispatch("projects/loadProject", "loner." + to.params.project).then(() => {
      next()
    })
      .catch(e => { console.log(e) })
  }
  else {

    next()
  }
})

export default router
