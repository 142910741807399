import axios from "axios"
import API_BASE from "./apiBase"
export default {
    namespaced: true,
    state: () => ({
        loaded: false,
        mainTitle: "Gilles Jacot",
        contactPages: [],
        loner: null,
        emailSubmitted: false,
        emailError: false,
        emailLoading: false
    }),
    mutations: {
        UPDATE_GENERAL(state, payload) {
            state.loaded = true;
            state.mainTitle = payload.mainTitle;
            state.contactPages = payload.contactPages;
            state.loner = payload.loner;
        },
        EMAIL_ERROR(state, payload) {
            state.emailError = payload
        },
        EMAIL_LOADING(state, payload) {
            state.emailLoading = payload
        },
        EMAIL_SUCCESS(state) {
            state.emailError = false
            state.emailSubmitted = true
        }
    },
    actions: {
        loadGeneral({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(API_BASE + "/api/?querytype=general")
                    .then(response => {
                        if ("error" in response.data) {
                            console.error(response.data.error)
                            reject(response.data.error)
                        }
                        else {
                            commit("UPDATE_GENERAL", response.data.data)
                        }
                        resolve()
                    })
            })

        },
        addEmail({ commit }, payload) {
            if (!payload.checked) {
                commit("EMAIL_LOADING", true)
                var bodyFormData = new FormData();
                bodyFormData.append('email', payload.email);
                axios({
                    method: "post",
                    url: API_BASE + "/api/",
                    data: bodyFormData,
                    headers: { "Content-Type": "multipart/form-data" },
                }).then(response => {
                    commit("EMAIL_LOADING", false)
                    if ("error" in response.data) {
                        commit("EMAIL_ERROR", true)
                    }
                    else {
                        commit("EMAIL_SUCCESS")

                    }
                })
            }
            else {
                commit("EMAIL_SUCCESS")
            }
        }
    },
    getters: {
        general(state) {
            return state;
        },
        e_error(state) {
            return state.emailError
        },
        e_submitted(state) {
            return state.emailSubmitted
        },
        e_loading(state) {
            return state.emailLoading
        },

    }
}