<template>
    <div>
        <template v-if="project.imageLink">
            <img :src="project.imageLink.imgs[1].url" class="linkImage" />
        </template>
        <span v-else class="title">
            {{(inMenu!=undefined?"-":"")+project.title}}
            <span class="location">{{project.location}}</span>
        </span>
    </div>
</template>

<script>
export default {
  props: ["project", "inMenu"],
};
</script>

<style lang="scss">
.firstLine div {
  display: inline-block;
}
.linkImage {
  height: $lineHeight * 1.2;
  width: auto;
  display: inline-block;
}
.title{
  // margin-left: $marginValue;

  font-style: italic;
}
.location {
  opacity: 0;
  // transition: opacity 400ms;

  font-style: normal;
}
</style>
