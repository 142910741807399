<template>
  <div>
    <div id="menuContainer">
      <div class="linkList">
        <div class="">Contact :</div>
        <template v-for="page in general.contactPages">
          <a class="link aboveLink" :href="page.url" :key="page.id">{{ page.title }}</a>
        </template>
      </div>
      <template v-for="el in projects">
        <template v-if="el.type == 'project'">
          <router-link :to="{ name: 'Project', params: { project: el.slug } }" :key="el.id" class="link"
            @mouseenter.native="setCurrProject(el)" @mouseleave.native="setCurrProject(false)">
            <ExpoTitle :project="el" :in-menu="true"/>
          </router-link>
        </template>
        <template v-else>
          <div class="linkList" :key="el.id">
            <div class="linkSectionTitle">{{ el.title }}</div>
            <router-link :to="{ name: 'ProjectInContainer', params: { projectContainer: el.slug, project: p.slug } }"
              v-for="p in el.children" :key="p.id" class="link" @mouseenter.native="setCurrProject(p)"
              @mouseleave.native="setCurrProject(false)">
              <ExpoTitle :project="p" :in-menu="true" />
            </router-link>
          </div>
        </template>
      </template>
      <router-link :to="{ name: 'LonerContainer' }" class="link lonerLink"
        @mouseenter.native="setCurrProject(general.loner)" @mouseleave.native="setCurrProject(false)">
        <img :src="general.loner.linkImage.imgs[1].url" class="linkImage" v-if="general.loner" />
      </router-link>
      <newsletter-signup />
    </div>
    <div id="flyerContainer">
      <template v-if="currProject">
        <image-element v-if="currProject.flyer" :img="currProject.flyer" alt sizes="30vw" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ExpoTitle from "@/components/ExpoTitle";
import newsletterSignup from "@/components/NewsletterSignup";
import ImageElement from "@/components/Image";
export default {
  name: "Navigation",
  data() {
    return {
      showMenu: false,
      currProject: false,
    };
  },
  components: { ExpoTitle, newsletterSignup, ImageElement },
  created() {
    // this.loadProjects();
  },
  computed: {
    ...mapGetters({
      general: "general/general",
      onMobile: "size/getMobile",
      projects: "projects/projects",
    }),
    ...mapState({
      navOpen: "size/navOpen",
    }),
    menuClosed() {
      return true;
    },
  },

  methods: {
    setCurrProject(p) {
      this.currProject = p;
    },
  },
};
</script>

<style lang="scss">
#flyerContainer {
  position: fixed;
  top: $globalPadding;
  right: $globalPadding;
  height: 66vh;
  width: 33vw;
  z-index: 1100;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: top right;
  }
}

.linkSectionTitle {
  cursor: default;
}
</style>