import axios from "axios"

import router from '@/router'
import API_BASE from "./apiBase"

function goToNotFound() {
    router.replace({ name: "NotFound" })
}
export default {

    namespaced: true,
    state: () => ({
        currProject: {
            loaded: false
        },
        projects: [],
        lonerProjects : [],
        loaded : false
    }),
    mutations: {
        UPDATE_CURR_PROJECT(state, payload) {
            state.currProject = payload;
            state.projects.forEach(p => {
                if (p.id == payload.id) {
                    p.contenu = payload.contenu;
                }
            })
        },
        UPDATE_PROJECTS(state, payload) {
            state.projects = payload.projects;
            state.lonerProjects = payload.lonerProjects;
            state.loaded = true;
        }
    },
    actions: {
        async loadProjects({ state, commit }) {
            return new Promise((resolve, reject) => {
                if (!state.projects.length) {
                    axios
                        .get(API_BASE + "/api/?querytype=projects")
                        .then(response => {
                            if ("error" in response.data) {
                                console.error(response.data.error)
                                goToNotFound()
                            }
                            else {
                                commit("UPDATE_PROJECTS", response.data.data)
                                resolve()
                            }
                        })
                        .catch(e => {
                            goToNotFound()
                            reject(e)
                        })
                }
            })
        },
        loadProject({ state, commit }, payload) {

            return new Promise((resolve, reject) => {

                var project = {};

                state.projects.forEach(p => {
                    if (p.slug == payload) {
                        project = p;
                    }
                })

                if (!("photos" in project)) {
                    if ("title" in project) {
                        commit("UPDATE_CURR_PROJECT", project)
                    }
                    var url = API_BASE + "/api/?querytype=project&id=" + payload
                    // console.log(url)
                    axios
                        .get(url)
                        .then(response => {
                            if ("error" in response.data) {
                                goToNotFound()
                                reject(response.data.error)
                            }
                            else {
                                response.data.data.loaded = true
                                commit("UPDATE_CURR_PROJECT", response.data.data)
                                resolve()
                            }
                        })
                        .catch(e => {
                            console.error(e)
                            goToNotFound()
                            reject(e)
                        })
                }
                else {
                    commit("UPDATE_CURR_PROJECT", project)
                    console.log("project retrieved from cache")
                }
            })

        }
    },
    getters: {
        projects(state) {
            return state.projects;
        },
        lonerProjects(state) {
            return state.lonerProjects;
        },
        project(state) {
            return state.currProject
        }
    }
}