<template>
    <div class="newsletterSignup">
        <span class="emailError" v-html="e_error?'The email address is invalid.':'&nbsp;'" />
        <input type="email" ref="email" placeholder="email address" v-if="!e_submitted" />
        <span v-else>Your email address has been submitted.</span>
        <br />
        <input type="checkbox" name="confirm" id="confirm" ref="confirm" />
        <input type="button" v-if="!e_submitted" @click="validateEmail" :value="e_loading ? 'loading...' : 'subscribe'" />
    </div>
</template>

<script>
const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters({
      e_submitted: "general/e_submitted",
      e_error: "general/e_error",
      e_loading: "general/e_loading",
    }),
  },
  methods: {
    ...mapActions({
      addEmail: "general/addEmail",
    }),
    ...mapMutations({
      setError: "general/EMAIL_ERROR",
    }),

    validateEmail() {
      var e = this.$refs.email.value;
      if (validateEmail(e.trim())) {
        this.addEmail({
          email: e.trim(),
          checked: this.$refs.confirm.checked,
        });
      } else {
        this.setError(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.newsletterSignup {
  margin-top: 4em;

  input[type="email"] {
    margin-bottom: 0.5em;
    width: 300px;
    max-width: 100%;
  }
  .emailError {
    color: red;
    display: block;
    margin-bottom: 0.5em;
  }
}
#confirm {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
</style>