<template>
    <div id="header">
        <template v-if="$route.name == 'Home'">
            <!-- {{general.mainTitle}} -->
        </template>
        <template v-else-if="$route.name == 'LonerProject'">
            <div id="backLink">
                <router-link :to="{ name: 'LonerContainer' }">
                    <img :src="general.loner.linkImage.imgs[1].url" class="linkImage" />
                </router-link>
            </div>

            <PageTitle class="pageTitle" />
        </template>
        <template v-else-if="$route.name != 'Home'">
            <div id="backLink">
                <router-link :to="{name : 'Home'}" class="homeLink">Home</router-link>
            </div>
            <PageTitle class="pageTitle" />
        </template>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PageTitle from "@/components/PageTitle";
export default {
  components: { PageTitle },
  created() {
    this.loadGeneral();
  },
  methods: {
    ...mapActions({
      loadGeneral: "general/loadGeneral",
    }),
  },
  computed: {
    ...mapGetters({
      general: "general/general",
    }),
  },
};
</script>

<style lang="scss">
#header {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  z-index: 10;
  padding: $globalPadding;
  display: flex;
  .location {
    opacity: 1;
  }
}
#backLink {
  flex-grow: 2;
  color: black;
  text-decoration: none;
  a {
    background-color: white;
    img {
      background-color: white;
    }
  }
}
.pageTitle {
  flex-grow: 2;
  text-align: right;
}

@media screen and (min-width: $mobileBreak) {
  #header {
    background-color: transparent;
    .pageTitle {
      display: none;
    }
  }
}
</style>